/*--------------STYLE--------------*/
.App {

}

html {
  min-height: 100%;
  background-color: #1F1F1F;
}

.center {
  text-align: center;
}


.row::after {
    content: "";
    clear: both;
    display: table;
}

.row {
  display: flex;
}

.blackBox {
  background-color: #1F1F1F;
}

/*--------------LINKS--------------*/
a:link {
    color: #1F1F1F;
    text-decoration: underline;
}

a:visited {
    color: #1F1F1F;
    text-decoration: none;
}

a:hover {
    color: white;
    text-decoration: underline;
}

a:active {
    color: black;
    text-decoration: underline;
}

/*--------------HEADER--------------*/
.header {
  max-width: 70%;
  padding: 10px 0px 0px 0px;
}

.navBox {
    background-color: #FF7ABD;
    border: 3px solid #2FD1F1;
    max-height: 3vw;
    padding: 0px;
}

.navText {
    font-family: 'Pacifico', cursive;
    font-size: 1vw;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -1.75vw;
}

.navBoxMobile {
    background-color: #FF7ABD;
    border: 3px solid #2FD1F1;
    padding: 1px;
}

.navTextMobile {
    font-family: 'Pacifico', cursive;
    font-size: 3vw;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -1.75vw;
}


/*--------------HOME--------------*/
.homeBody {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  background-color: #1F1F1F;
}

.imgContainer {
  width: 33%;
  padding: 10px;
  margin-top: 1%;
  margin-bottom: 1%;
}

.homeImg {
  width: 100%;
  height: auto;
}

.imgContainerMobile {
  width: 90%;
  padding: 10px;
  margin-top: 1%;
  margin-bottom: 1%;
  margin-left: auto;
  margin-right: auto;
}

.homeImgMobile {
  width: 100%;
  height: auto;
}

.mobiletest {
  color: white;
}

/*--------------ABOUT--------------*/
.about {
  padding: 10px;
  margin-left: 5%;
  margin-right: 5%;
}

.about h1 {
  font-family: 'Barrio';
  color: #2FD1F1;
  font-size: 3vw;
}

.about h3 {
  color: white;
  font-family: 'Roboto', sans-serif;
  font-size: 1.4vw;
}

.width75 {
  margin-right: 5%;
}

.imgCont25 {
  width: 100%;
}

.aboutImg {
  width: 100%;
  margin-top: 5%;
  margin-bottom: 5%;
}

.aboutMobile {
  padding: 10px;
  margin-left: 5%;
  margin-right: 5%;
  text-align: center;
}

.aboutMobile h1 {
  font-family: 'Barrio';
  color: #2FD1F1;
  font-size: 8vw;
}

.aboutMobile h3 {
  color: white;
  font-family: 'Roboto', sans-serif;
  font-size: 4vw;
}

.imgContMobile {
  width: 40%;
  margin-left: auto;
  margin-right: auto;
}

.aboutImgMobile {
  width: 100%;
  margin-top: 5%;
  margin-bottom: 5%;
}

.mixcloud {
  width: 33%;
  margin-left: 5%;
  margin-right: 5%;
  margin-bottom: 2%;
}


/*--------------EVENTS--------------*/
.event {
  width: 40%;
  margin-left: 30%;
  text-align: center;
}

.eventImg {
  width: 100%;
}

.eventWide {
  width: 80%;
  margin-left: 10%;
  text-align: center;
}

/*--------------CONTACT--------------*/
.icon {
  width: 10%;
  margin-left: 5%;
  margin-right: 5%;
}

.iconMobile {
  width: 20%;
  margin-left: auto;
  margin-right: auto;
}

.iconImgMobile {
  width: 100%;
}

/*--------------FOOTER--------------*/
footer {
  text-align: center;
  background-color: #FF7ABD;
  border: 3px solid #2FD1F1;
  padding: 0px;
  font-size: 1vw;
  font-family: 'Roboto', sans-serif;
  max-height: 3vw;
}

footer h3 {
    margin-top: .75vw;
}

.footerMobile {
  text-align: center;
  background-color: #FF7ABD;
  border: 3px solid #2FD1F1;
  padding: 1px;
  font-size: 3vw;
  font-family: 'Roboto', sans-serif;
}

.footerMobile h3 {
    margin-top: 2vw;
}
